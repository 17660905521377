// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

/* eslint-disable import/prefer-default-export */
export function getSearch() {
  const search = typeof window !== `undefined` ? window.location.search : '';
  return search;
}

export function useSearch() {
  const [search, setSearch] = useState('');
  useEffect(() => {
    const q = typeof window !== 'undefined' ? window.location.search : '';
    setSearch(q);
  }, []);
  return search;
}

// 从 cpm 中获取用户同意信息
export function getDataFromCpm(itemName) {
  if (!itemName) {
    console.log('请传入要查找的 cookie 类型名称，如"网站必须"');
    return false;
  }
  if (!window.clab_cpm) {
    console.log('cpm 未加载');
    return false;
  }
  const cookies = window.clab_cpm.getUserConsent();
  // status:"OPT_IN"|"OPT_OUT"|"SEEN"
  // OPT_IN 同意 OPT_OUT 不同意  SEEN 仅看过，未明确设置过，SEEN 在checkbox 状态和 OPT_OUT一致
  return (cookies.find((item) => item.name === itemName) || {}).status === 'OPT_IN';
}

export function beforeDownload(
  href,
  leaveInfo,
  id,
  setHref,
  setModalType,
  pageFrom,
  setPageFrom,
  setFileId,
  pageIndex = 0
) {
  if (!href) return;
  if (!leaveInfo) window.location.href = href;
  // 从 cpm 获取用户同意信息
  const enabledCustomerCookie = getDataFromCpm('网站必须');
  // 查询 cookie，是否 6 个月内曾经留资[申请试用或下载资源，两种弹框只要有一个提交成功过即可]
  const cookies = document.cookie;
  if (enabledCustomerCookie && cookies.includes('cl_info_uploaded')) {
    // 如果已留资，直接下载
    if (pageFrom === 'index') {
      // 大概率不会从首页来
      window.open(`/whitepaper${pageIndex !== 0 ? `/${pageIndex + 1}/` : ''}${getSearch()}#${id}`);
    } else {
      document.getElementById(id).click();
    }
  } else {
    // 如果未留资，或超过期限：弹出留资弹框
    setHref(href);
    setModalType('download');
    if (setPageFrom) setPageFrom(pageFrom);
    if (setFileId) setFileId(id);
    document.querySelector('.apply-btn').click();
  }
}

export function onMenuSelect(props, callback) {
  const { key } = props;
  const { pathname } = window.location;
  const keyPath = key.replace('/', '');
  if ((keyPath === 'home' && pathname === '/') || pathname.includes(`/${keyPath}`)) {
    window.location.href = `/${keyPath === 'home' ? '' : keyPath}`;
  } else if (keyPath.includes('about/#contacts') && pathname.includes('about')) {
    window.location.hash = '#contacts';
    window.location.reload();
  } else if (key.includes('http')) {
    if (callback) callback();
  }
}

export function hashSectionScrollIntoView() {
  if (typeof window === 'undefined') {
    return;
  }
  const hash = window.location.hash.split('#')[1] || '';
  if (hash && document.getElementById(`section-${hash}`)) {
    setTimeout(() => {
      document.getElementById(`section-${hash}`).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }, 500);
  }
}

export function loadScript(src, callback) {
  let script = document.createElement('script');
  let head = document.getElementsByTagName('body')[0];
  script.type = 'text/javascript';
  script.charset = 'UTF-8';
  script.src = src;
  if (script.addEventListener) {
    script.addEventListener(
      'load',
      function () {
        callback();
      },
      false
    );
  } else if (script.attachEvent) {
    script.attachEvent('onreadystatechange', function () {
      let target = window.event.srcElement;
      if (target.readyState === 'loaded') {
        callback();
      }
    });
  }
  head.appendChild(script);
}

// ada 企微活码
export function loadQrcodeQw(buttonId) {
  const dataSource = {
    side: {
      bindingId: '4cj8',
      path: '//wework.qpic.cn/wwpic/851344_0Kj20mb7SHWbsaN_1636624452/0',
      qrId: '_cl_wework_qrcode_side',
    },
    footer: {
      bindingId: '4cj9',
      path: '//wework.qpic.cn/wwpic/50884_r1oxP9hgQ-i2bf5_1636624569/0',
      qrId: '_cl_wework_qrcode_footer',
    },
    contactus: {
      bindingId: '4cja',
      path: '//wework.qpic.cn/wwpic/603375_O5d2ema6QDaah7k_1636624614/0',
      qrId: '_cl_wework_qrcode_contactus',
    },
    activitycpm: {
      // cpm 直播页底部
      bindingId: '4ckD',
      path: '//wework.qpic.cn/wwpic/870011_oSEjD2XKRkuaRtn_1639375352/0',
      qrId: '_cl_wework_qrcode_cpm',
    },
    activitycpm1: {
      // cpm 直播页开播提醒弹框
      bindingId: '4ckD',
      path: '//wework.qpic.cn/wwpic/870011_oSEjD2XKRkuaRtn_1639375352/0',
      qrId: '_cl_wework_qrcode_cpm1',
    },
    activitycpm2: {
      // cpm 直播页当前观看人数过多弹框
      bindingId: '4ckD',
      path: '//wework.qpic.cn/wwpic/870011_oSEjD2XKRkuaRtn_1639375352/0',
      qrId: '_cl_wework_qrcode_cpm2',
    },
    // 加入社群侧边栏
    joinGroup: {
      bindingId: '4cJs',
      path: '//wework.qpic.cn/wwpic/7606_1w6w-2f7TjOjrJk_1698631541/0',
      qrId: '_cl_wework_qrcode_join_group_nav',
    },
    // 加入社群底部
    joinGroup1: {
      bindingId: '4cJu',
      path: '//wework.qpic.cn/wwpic/250716_FWNGorbeRfyjWv6_1698631653/0',
      qrId: '_cl_wework_qrcode_join_group_footer',
    },
    // 在线咨询-侧边栏
    onlineConsulting: {
      bindingId: '4cJt',
      path: '//wework.qpic.cn/wwpic/828461_UhG2PjvzSNu2_x0_1698631557/0',
      qrId: '_cl_wework_qrcode_online_consulting_nav',
    },
    // 在线咨询-挽回表单
    onlineConsulting1: {
      bindingId: '4cJr',
      path: '//wework.qpic.cn/wwpic/234593_L08vocRsTC6mNMQ_1698630650/0',
      qrId: '_cl_wework_qrcode_online_consulting_form',
    },
    // 在线咨询-联系我们
    onlineConsulting2: {
      bindingId: '4cJv',
      path: '//wework.qpic.cn/wwpic/168992_lwhraIS7RKyPRwy_1698634963/0',
      qrId: '_cl_wework_qrcode_online_consulting_contactus',
    },
  };
  const data = dataSource[buttonId];
  if (!document.getElementById(data.qrId)) {
    return;
  }
  (function (c, e, o, b) {
    var g = document.createElement('img');
    function d() {
      var ru = (function (j) {
          for (var h = j + '=', g = document.cookie.split(';'), k = 0; k < g.length; k++) {
            for (var l = g[k]; ' ' == l.charAt(0); ) l = l.substring(1);
            if (0 == l.indexOf(h)) return l.substring(h.length, l.length);
          }
          return '';
        })('c__utma'),
        oReq = (function () {
          let cl = [];
          return (
            location.search
              .slice(1)
              .split('&')
              .forEach(function (a) {
                a = a.split('=');
                0 === a[0].indexOf('cl_') && a[1] && cl.push(a[0] + '=' + a[1]);
              }),
            0 < cl.length ? '&' + cl.join('&') : ''
          );
        })(),
        ru = c + '?uuid=' + e + '&utma=' + ru + oReq;
      oReq = new XMLHttpRequest();
      (oReq.onload = function () {
        try {
          var r = JSON.parse(this.responseText);
          r && r.qrCode && g.setAttribute('src', r.qrCode);
        } catch (e) {}
      }),
        oReq.open('GET', ru),
        oReq.send();
    }
    g.setAttribute('style', 'width: 100%');
    g.setAttribute('src', o);
    const parent = document.getElementById(b);
    parent.childNodes[0] && parent.removeChild(parent.childNodes[0]);
    parent.appendChild(g);
    'undefined' != typeof _cl_tracker && _cl_tracker.ready
      ? _cl_tracker.ready(d)
      : setTimeout(d, 3e3);
  })('//a.huiju.cool/e/extwechatcorp/pgw/livecode/binding', data.bindingId, data.path, data.qrId);
}

// 博客文章右上角的活码
export function loadQrcode() {
  // 必须有这个 setTimeout， 否则用户点击同意 cookie 授权后，加载图片会失败
  setTimeout(function () {
    (function (c, e, f, b) {
      function a(j) {
        var h = j + '=';
        var g = document.cookie.split(';');
        for (var k = 0; k < g.length; k++) {
          var l = g[k];
          while (l.charAt(0) == ' ') {
            l = l.substring(1);
          }
          if (l.indexOf(h) == 0) {
            return l.substring(h.length, l.length);
          }
        }
        return '';
      }
      (function () {
        var h = a('c__utma');
        document.querySelectorAll(b).forEach(function (node) {
          var g = document.createElement('img');
          g.setAttribute('style', 'width: 160px');
          g.setAttribute('src', c + '?uuid=' + e + '&tid=' + f + '&utma=' + h);
          node.childNodes[0] && node.removeChild(node.childNodes[0]);
          node.appendChild(g);
        });
      })();
    })(
      '//host.huiju.cool/qrcode/binding',
      '698f8024935b493696d2581fec91b8f5',
      '1032079986',
      '#_cl_qrcode'
    );
  }, 1500);
}

export function loadWechatOffcialQrcode(uuid, tid, eleId) {
  setTimeout(function () {
    (function (c, e, f, b) {
      function a(j) {
        var h = j + '=';
        var g = document.cookie.split(';');
        for (var k = 0; k < g.length; k++) {
          var l = g[k];
          while (l.charAt(0) == ' ') {
            l = l.substring(1);
          }
          if (l.indexOf(h) == 0) {
            return l.substring(h.length, l.length);
          }
        }
        return '';
      }
      function d() {
        var h = a('c__utma');
        var g = document.createElement('img');
        setTimeout(function () {
          g.setAttribute('style', 'width: 100%');
          g.setAttribute('src', c + '?uuid=' + e + '&tid=' + f + '&utma=' + h + '&ts=' + Date.now());
          g.onload = function load() {
            const container = document.getElementById(b);

            if (container) {
              const firstChild = container.childNodes[0];
              if (firstChild) {
                container.removeChild(container.childNodes[0]);
              }
              container.appendChild(g);
            }
          };
          g.onerror = function error() {
            console.log('load dynamic qrcode error');
          };
        }, 3000);
      }
      if (typeof _cl_tracker != 'undefined' && _cl_tracker.ready) {
        _cl_tracker.ready(d);
      } else {
        setTimeout(d, 1000);
      }
    })('//host.huiju.cool/qrcode/binding', uuid, tid, eleId);
  }, 1500);
}

export function loadQrcodeOffcialByType(type) {
  const dataSource = {
    side_wechat_official: {
      uuid: '02101d6eef094b3faa8728ab256b644d',
      tid: '1032079986',
      eleId: '_cl_qrcode_official_side',
    },
    footer_wechat_official: {
      uuid: '1ddb834f37f54a12b89ae079fc799b89',
      tid: '1032079986',
      eleId: '_cl_qrcode_official_footer',
    },
    blog_article: {
      uuid: 'ff876c071eb548598d76be0a295f497d',
      tid: '1032079986',
      eleId: '_cl_qrcode_blog_article',
    },
    contact_us: {
      uuid: 'c6ff72eef0f94888bcabfcc688148c30',
      tid: '1032079986',
      eleId: '_cl_qrcode_contactus',
    },
  };
  console.log('load dynamic qrcode', type, dataSource[type]);
  loadWechatOffcialQrcode(dataSource[type].uuid, dataSource[type].tid, dataSource[type].eleId);
}

// 之前写在layout里的脚本
function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

// 加载百度热力图埋点脚本
export function loadBaiduhmScript() {
  // const _hmt = _hmt || [];
  const hm = document.createElement('script');
  hm.id = 'baiduhm';
  hm.src = 'https://hm.baidu.com/hm.js?f039e7b9ae45de69f8a695acea6993ee';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
}

// 移除百度热力图埋点脚本
export function removeBaiduhmScript() {
  document.getElementById('baiduhm').remove();
}
